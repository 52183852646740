<template>
  <div>
    <d-button
      :text="selectedModel ? 'admin.duplicate.without-model' : 'admin.duplicate.with-model'"
      class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
      @on:button-click="scrollToCategoryList"
    />

    <product-list
      v-if="selectedModel || getSelectedCategory"
    />

    <div v-if="getSelectedCategory" class="border-bottom-grey mt-4 mb-4"/>

    <product-list-duplicate
      v-show="!selectedModel || getSelectedCategory"
      id="categoryListDuplicateId"
    />
  </div>
</template>
<script>
import {getClubs} from "@api/services/club/club.api";


export default {
  components: {
    ProductList:()=> import('./ProductList'),
    ProductListDuplicate: ()=> import('./ProductListDuplicate')
  },
  data: () => ({
    duplicationModel: false,
    search: '',
    withModel: false,
    selectedModel: false,
    clubs: [],
    tabs: [
      {name: 'admin.duplicate.menus.categories', active: true, id: 'categories'},
      {name: 'admin.duplicate.menus.products', active: false, id: 'products'},
      {name: 'admin.duplicate.menus.playgrounds', active: false, id: 'Terrains'},
      {name: 'admin.duplicate.menus.options', active: false, id: 'options'},
      {name: 'admin.duplicate.menus.calendars', active: false, id: 'calendars'},
      {name: 'admin.duplicate.menus.block-prices', active: false, id: 'block-prices'},
      {name: 'admin.duplicate.menus.category-block-prices', active: false, id: 'category-block-prices'},
      {name: 'admin.duplicate.menus.promotional-code', active: false, id: 'promotional-code'},
      {name: 'admin.duplicate.menus.ticket', active: false, id: 'ticket'},
      {name: 'admin.duplicate.menus.closures', active: false, id: 'closures'},
    ]
  }),
  computed: {
    clubsIsLoaded() {
      return this.$store.getters['club/getIsLoaded'];
    },
    isCategoryListActivated() {
      return this.$store.getters['duplicate/getActivateCategoryList'];
    },
    getSelectedCategory() {
      return this.$store.getters['duplicate/getSelectedCategoryModel'];
    }
  },
  methods: {
    scrollToCategoryList() {
      this.selectedModel = !this.selectedModel;
      this.$nextTick(() => {
        this.$store.commit('duplicate/setSelectedCategoryModel', null);
        setTimeout(() => {
          document.getElementById('categoryListDuplicateId').scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 300)
        ;
      });
    },
    loadClubs(filter = '') {
      this.clubs = [];
      getClubs(1, 500, filter)
        .then((response) => {
          this.$store.commit('club/setClubs', response.data['hydra:member']);

          for (const club of response.data['hydra:member']) {
            this.clubs.push({
              label: club.name,
              id: club.id,
              item: club,
              iri: club['@id'],
            });
          }
        })
      ;
    },
  },
  mounted() {
    this.loadClubs();
  }
}
</script>
